@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

@layer utilities {
  .max-w-containerSm {
    max-width: calc(100vw - 32px);
  }
}
 
/* ============= NavLink active Start here ============== */
nav .active li {
  color: rgb(241,89,41);
  font-weight: 600;
  text-decoration: 1px underline rgb(241,89,41);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #1a1a1a;
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #C1C1C1;
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #C1C1C1;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */
