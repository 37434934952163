.slider-container {
  overflow: hidden;
  white-space: nowrap;
  background-color: #d5a866; /* İstediğiniz arka plan rengi */
  padding: 10px 0;
}

.slider {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

.icon {
  width: 24px ;
  height: 24px;
}

.brand-item {
  display: inline-flex;
  align-items: center;
  padding: 0 20px; /* İkonlar ve markalar arasındaki boşluk */
  font-family: 'Nunito Sans', sans-serif; /* Yazı fontu */
  font-size: 16px;
  color: black;
}

.icon {
  font-size: 20px; /* İkon boyutu */
  margin-right: 10px; /* İkon ile marka arasındaki boşluk */
}

.brand-name {
  padding: 0 10px; /* Marka adı ile ikon arasındaki boşluk */
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
